
























import { MnemonicInputTs } from './MnemonicInputTs';
export default class MnemonicInput extends MnemonicInputTs {}
