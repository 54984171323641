





































import ImportMnemonicTs from './ImportMnemonicTs';

export default class ImportMnemonic extends ImportMnemonicTs {}
